<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="message-header" slot="header">
      <p class="message-header-title">{{ $t(messageData.title) }}</p>
    </div>
    <div class="message-component" slot="component">
      <p class="message-component-data word-break">{{ messageData.message }}</p>
    </div>
  </Popup>
</template>

<script>
export default {
  name: "message",
  props: {
    closePopup: {
      type: Function,
    },
    messageData: {
      type: Object,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "20% auto",
      radiusType: "full",
    };
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./message.scss"
</style>
